import React, { useCallback, useEffect, useState } from 'react';
import { convertValueToFloat, formatarMoeda } from '../../util/Utilitarios';
import { TableVirtuoso } from 'react-virtuoso';
import MensagemPadraoRelatorio from '../../components/RelatorioEspecifico/RelatorioSemDados'
import { BrowserRouter, Link } from 'react-router-dom'
import { useSortBy } from 'react-table';
import { getDistinctPropertyValues, limitarCaracteres } from '../../util/FuncoesComuns';

const relatorioMovimentacoesBancariasEspecifico = (array, arrayConfigCol, configOrdenamento, htmlHeader) => {

    return (
        array && array[0]?.data && array[0]?.data.length > 0 ? array.map((x, idx) => {

            const idxBancoContaDesc = arrayConfigCol[idx].findIndex(y => y.coluna == 'banco_conta_descricao')
            const idxAgencia = arrayConfigCol[idx].findIndex(y => y.coluna == 'agencia')
            const idxSaldo = arrayConfigCol[idx].findIndex(y => y.coluna == 'saldo')
            // const idxReferencia = arrayConfigCol[idx].findIndex(y => y.coluna == 'referencia')
            const idxConta = arrayConfigCol[idx].findIndex(y => y.coluna == 'conta')
            const idxTipoConta = arrayConfigCol[idx].findIndex(y => y.coluna == 'tipo_conta')
            const idxAtivo = arrayConfigCol[idx].findIndex(y => y.coluna == 'ativo')

            return (<>
                {x.data.map((item, index) => {
                    return (<>
                        {index > 0 ? htmlHeader : <></>}
                        <table className='table-agrupamento mb-2'>

                            <tr className={'tr-data titulo-1 group-title bold coluna'}>
                                <td className={` bold td-data text-left`} style={{ width: '45%' }}>{item.empresa} </td>
                            </tr>
                        </table>

                        <table style={{ width: '100%' }} className='table-agrupamento mb-2'>
                            {
                                item.bancos.map((banco, index) => {

                                    const valorTotalBanco = banco?.banco_contas.reduce((acc, obj) => {
                                        if (obj && typeof obj.saldo_bancario === 'number') {
                                            return acc + obj.saldo_bancario;
                                        }
                                        return acc;
                                    }, 0);
                                    return (
                                        <>
                                            <tr >
                                                <td className={`bold text-left`} colSpan={5} style={{ paddingBottom: '10px' }}>
                                                    {banco.banco}
                                                </td>

                                            </tr>

                                            {
                                                banco.banco_contas.map((banco_contas, index) => {

                                                    const totalReceita = banco_contas?.lancamentos_liquidados?.reduce((acc, dia) => {
                                                        const receitaDia = dia.lancamentos.reduce((soma, lancamento) => soma + (lancamento.receita || 0), 0);
                                                        return acc + receitaDia;
                                                    }, 0) || 0;

                                                    const totalDespesa = banco_contas?.lancamentos_liquidados?.reduce((acc, dia) => {
                                                        const despesaDia = dia.lancamentos.reduce((soma, lancamento) => soma + (lancamento.despesa || 0), 0);
                                                        return acc + despesaDia;
                                                    }, 0) || 0;

                                                    const totalAplicacao = banco_contas?.lancamentos_liquidados?.reduce((acc, dia) => acc + (dia.aplicacao || 0), 0) || 0;

                                                    const totalResgate = banco_contas?.lancamentos_liquidados?.reduce((acc, dia) => acc + (dia.resgate || 0), 0) || 0;

                                                    const saldoAtual = (banco_contas?.saldo_bancario || 0) + (totalReceita - totalDespesa) + totalAplicacao + totalResgate;

                                                    return (
                                                        <>

                                                            <tr className=' tr-data titulo-1 group-title bold coluna'>
                                                                <td className='text-center bold td-data group-title' width={'1%'}>#</td>

                                                                {x.colunas && arrayConfigCol[idx][idxAgencia]?.visivel ? <td className='text-left bold td-data' width={'35%'}>Descrição Conta</td> : <></>}
                                                                {x.colunas && arrayConfigCol[idx][idxBancoContaDesc]?.visivel ? <td className='text-left bold td-data' width={'10%'}>Agência</td> : <></>}
                                                                {x.colunas && arrayConfigCol[idx][idxConta]?.visivel ? <td className='bold td-data' width={'10%'}>Conta</td> : <></>}
                                                                {/* {x.colunas && arrayConfigCol[idx][idxReferencia]?.visivel ? <td className='bold td-data' width={'10%'}>Referência</td> : <></>} */}
                                                                {x.colunas && arrayConfigCol[idx][idxTipoConta]?.visivel ? <td className='bold td-data text-center' width={'10%'}>Tipo Conta</td> : <></>}
                                                                {x.colunas && arrayConfigCol[idx][idxAtivo]?.visivel ? <td className='bold td-data text-right' width={'10%'}>Status Conta</td> : <></>}
                                                                {/* {x.colunas && arrayConfigCol[idx][idxSaldo]?.visivel ? <td className='bold td-data text-center' width={'15%'}>Saldo</td> : <></>} */}

                                                            </tr>


                                                            <tr className={'tr-data bt-1'}>
                                                                <td className='text-center td-data'>{index + 1}</td>
                                                                {x.colunas && arrayConfigCol[idx][idxAgencia]?.visivel ? <td className='text-left td-data'>{banco_contas.banco_conta_descricao}</td> : <></>}
                                                                {x.colunas && arrayConfigCol[idx][idxBancoContaDesc]?.visivel ? <td className='text-left td-data'>{banco_contas.agencia}</td> : <></>}
                                                                {x.colunas && arrayConfigCol[idx][idxConta]?.visivel ? <td className='td-data'>{banco_contas.conta}</td> : <></>}
                                                                {/* {x.colunas && arrayConfigCol[idx][idxReferencia]?.visivel ? <td className='td-data'>{banco_contas.referencia}</td> : <></>} */}
                                                                {x.colunas && arrayConfigCol[idx][idxTipoConta]?.visivel ? <td className='td-data text-left '>{banco_contas.tipo_conta}</td> : <></>}
                                                                {x.colunas && arrayConfigCol[idx][idxAtivo]?.visivel ? <td className='td-data text-right'>{banco_contas.ativo}</td> : <></>}
                                                                {/* {x.colunas && arrayConfigCol[idx][idxSaldo]?.visivel ? <td className='td-data text-right'>{formatarMoeda(banco_contas.saldo_bancario)}</td> : <></>} */}
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={8} className='td-data text-right'>
                                                                    <b>Saldo Anterior: {formatarMoeda(banco_contas.saldo_bancario)}</b>
                                                                </td>
                                                            </tr>
                                                            <tr>



                                                                <td colSpan="8">
                                                                    <table style={{ width: '100%' }}>
                                                                        <tr className='titulo-1'>
                                                                            <td className='text-center bold td-data group-title' width={'1%'}>#</td>
                                                                            <td className='text-left bold td-data' width={'5%'}>Codigo Lançamento</td>
                                                                            <td className='text-left bold td-data' width={'10%'}>Data Liquidação</td>
                                                                            <td className='text-left bold td-data' width={'30%'}>Categoria</td>
                                                                            <td className='bold td-data' width={'20%'}>Desc. Lançamento</td>
                                                                            <td className='bold td-data' width={'25%'}>Cliente</td>
                                                                            <td className='bold td-data text-center' width={'10%'}>Valor</td>
                                                                            {/* <td className='bold td-data text-center' width={'10%'}>Receita</td>
                                                                            <td className='bold td-data text-right' width={'10%'}>Despesa</td> */}
                                                                        </tr>

                                                                        {
                                                                            banco_contas?.lancamentos_liquidados && banco_contas.lancamentos_liquidados.length > 0 ? (
                                                                                banco_contas.lancamentos_liquidados.map((lancamento_liquidados, idxLiquidados) => (
                                                                                    <>
                                                                                        {
                                                                                            lancamento_liquidados?.lancamentos?.map((lancamentos, idxLancamentos) => (
                                                                                                <tr className={'tr-data bt-1'} key={idxLancamentos}>
                                                                                                    <td className='text-center td-data'>{idxLancamentos + 1}</td>
                                                                                                    <td className='text-left td-data'>{lancamentos.lancamento_id}</td>
                                                                                                    <td className='text-left td-data'>{lancamento_liquidados.data_liquidacao}</td>
                                                                                                    <td className='td-data'>{lancamentos.categoria}</td>
                                                                                                    <td className='td-data'>{lancamentos.descricao_lancamento}</td>
                                                                                                    <td className='td-data text-left'>{lancamentos.cliente}</td>
                                                                                                    <td className='td-data text-right'>{formatarMoeda(lancamentos.valor)}</td>
                                                                                                    {/* <td className='td-data text-right'>{formatarMoeda(lancamentos.receita)}</td>
                                                                                                    <td className='td-data text-right'>{formatarMoeda(lancamentos.despesa)}</td> */}
                                                                                                </tr>
                                                                                            ))
                                                                                        }

                                                                                        <tr>
                                                                                            <td colSpan={8} className='td-data text-right' style={{ borderTop: '1px #000 dashed' }}>
                                                                                                <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                                                                    <b> {formatarMoeda(lancamento_liquidados.saldo_dia_total)}</b>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>

                                                                                    </>
                                                                                ))


                                                                            ) : (
                                                                                <tr>
                                                                                    <td colSpan='8' className='text-center td-data'>Nenhum Lançamento Encontrado</td>
                                                                                </tr>
                                                                            )
                                                                        }


                                                                        {

                                                                            <tr>
                                                                                <td colSpan={8} className='td-data text-right' style={{ borderTop: '1px #000 dashed' }}>
                                                                                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                                                        <b> Total Receitas: {formatarMoeda(totalReceita)}</b>
                                                                                    </div>

                                                                                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                                                        <b> Total Despesas: {formatarMoeda(totalDespesa * (- 1))}</b>
                                                                                    </div>

                                                                                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                                                        <b> Aplicação: {formatarMoeda(totalAplicacao)}</b>
                                                                                    </div>

                                                                                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                                                        <b> Resgate: {formatarMoeda(totalResgate)}</b>
                                                                                    </div>

                                                                                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                                                        <b> Saldo Atual: {formatarMoeda(saldoAtual)}</b>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>

                                                                        }

                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </>
                                                    )
                                                })}


                                        </>

                                    )


                                })
                            }

                        </table>
                    </>)
                })}
            </>)

        }) : <MensagemPadraoRelatorio />
    )
}

const relatorioMovimentacoesBancariasVirtuoso = (array, arrayConfigCol, configOrdenamento, htmlHeader) => {

    return (
        array && array[0]?.data && array[0]?.data.length > 0 ? array.map((x, idx) => {

            const idxBancoContaDesc = arrayConfigCol[idx].findIndex(y => y.coluna == 'banco_conta_descricao')
            const idxAgencia = arrayConfigCol[idx].findIndex(y => y.coluna == 'agencia')
            const idxSaldo = arrayConfigCol[idx].findIndex(y => y.coluna == 'saldo')
            // const idxReferencia = arrayConfigCol[idx].findIndex(y => y.coluna == 'referencia')
            const idxConta = arrayConfigCol[idx].findIndex(y => y.coluna == 'conta')
            const idxTipoConta = arrayConfigCol[idx].findIndex(y => y.coluna == 'tipo_conta')
            const idxAtivo = arrayConfigCol[idx].findIndex(y => y.coluna == 'ativo')

            // const valorTotalGeral = x.data.reduce((acc, obj) => convertValueToFloat(acc) + convertValueToFloat(obj.saldo_total_empresa), 0);

            return (<>
                <div className='delete-starts' />
                <TableVirtuoso
                    style={{ height: 1000, }}
                    useWindowScroll
                    data={x.data}
                    itemContent={(index, item) => (
                        <>
                            {index > 0 ? htmlHeader : <></>}
                            <table className='table-agrupamento mb-2'>

                                <tr className={'tr-data titulo-1 group-title bold coluna'}>
                                    <td className={` bold td-data text-left`} style={{ width: '45%' }}>{item.empresa} </td>
                                </tr>
                            </table>

                            <table style={{ width: '100%' }} className='table-agrupamento mb-2'>
                                {
                                    item.bancos.map((banco, index) => {

                                        return (
                                            <>
                                                <tr >
                                                    <td className={`bold text-left`} colSpan={5} style={{ paddingBottom: '10px' }}>
                                                        {banco.banco}
                                                    </td>

                                                </tr>

                                                {
                                                    banco.banco_contas.map((banco_contas, index) => {

                                                        const totalReceita = banco_contas?.lancamentos_liquidados?.reduce((acc, dia) => {

                                                            const receitaDia = dia.lancamentos.reduce((soma, lancamento) => {
                                                                console.log('lancamento: ', lancamento);
                                                                if (lancamento.tipo_movimento_id == null) {
                                                                    return soma + (lancamento.receita || 0);
                                                                }
                                                                return soma;
                                                            }, 0);

                                                            return acc + receitaDia;
                                                        }, 0) || 0;

                                                        const totalDespesa = banco_contas?.lancamentos_liquidados?.reduce((acc, dia) => {
                                                            const despesaDia = dia.lancamentos.reduce((soma, lancamento) => {
                                                                if (lancamento.tipo_movimento_id == null) {
                                                                    return soma + (lancamento.despesa || 0);
                                                                }
                                                                return soma;
                                                            }, 0);
                                                            return acc + despesaDia;
                                                        }, 0) || 0;

                                                        const totalAplicacao = banco_contas?.lancamentos_liquidados?.reduce((acc, dia) => acc + (dia.aplicacao || 0), 0) || 0;

                                                        const totalResgate = banco_contas?.lancamentos_liquidados?.reduce((acc, dia) => acc + (dia.resgate || 0), 0) || 0;

                                                        const saldoAtual = (banco_contas?.saldo_bancario || 0) + (totalReceita - totalDespesa);

                                                        return (
                                                            <>

                                                                <tr className=' tr-data titulo-1 group-title bold coluna'>
                                                                    <td className='text-center bold td-data group-title' width={'1%'}>#</td>

                                                                    {x.colunas && arrayConfigCol[idx][idxAgencia]?.visivel ? <td className='text-left bold td-data' width={'35%'}>Descrição Conta</td> : <></>}
                                                                    {x.colunas && arrayConfigCol[idx][idxBancoContaDesc]?.visivel ? <td className='text-left bold td-data' width={'10%'}>Agência</td> : <></>}
                                                                    {x.colunas && arrayConfigCol[idx][idxConta]?.visivel ? <td className='bold td-data' width={'10%'}>Conta</td> : <></>}
                                                                    {/* {x.colunas && arrayConfigCol[idx][idxReferencia]?.visivel ? <td className='bold td-data' width={'10%'}>Referência</td> : <></>} */}
                                                                    {x.colunas && arrayConfigCol[idx][idxTipoConta]?.visivel ? <td className='bold td-data text-center' width={'10%'}>Tipo Conta</td> : <></>}
                                                                    {x.colunas && arrayConfigCol[idx][idxAtivo]?.visivel ? <td className='bold td-data text-right' width={'10%'}>Status Conta</td> : <></>}
                                                                    {/* {x.colunas && arrayConfigCol[idx][idxSaldo]?.visivel ? <td className='bold td-data text-center' width={'15%'}>Saldo</td> : <></>} */}

                                                                </tr>


                                                                <tr className={'tr-data bt-1'}>
                                                                    <td className='text-center td-data'>{index + 1}</td>
                                                                    {x.colunas && arrayConfigCol[idx][idxAgencia]?.visivel ? <td className='text-left td-data'>{banco_contas.banco_conta_descricao}</td> : <></>}
                                                                    {x.colunas && arrayConfigCol[idx][idxBancoContaDesc]?.visivel ? <td className='text-left td-data'>{banco_contas.agencia}</td> : <></>}
                                                                    {x.colunas && arrayConfigCol[idx][idxConta]?.visivel ? <td className='td-data'>{banco_contas.conta}</td> : <></>}
                                                                    {/* {x.colunas && arrayConfigCol[idx][idxReferencia]?.visivel ? <td className='td-data'>{banco_contas.referencia}</td> : <></>} */}
                                                                    {x.colunas && arrayConfigCol[idx][idxTipoConta]?.visivel ? <td className='td-data text-left '>{banco_contas.tipo_conta}</td> : <></>}
                                                                    {x.colunas && arrayConfigCol[idx][idxAtivo]?.visivel ? <td className='td-data text-right'>{banco_contas.ativo}</td> : <></>}
                                                                    {/* {x.colunas && arrayConfigCol[idx][idxSaldo]?.visivel ? <td className='td-data text-right'>{formatarMoeda(banco_contas.saldo_bancario)}</td> : <></>} */}
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={8} className='td-data text-right'>
                                                                        <b>Saldo Anterior: {formatarMoeda(banco_contas.saldo_bancario)}</b>
                                                                    </td>
                                                                </tr>
                                                                <tr>



                                                                    <td colSpan="8">
                                                                        <table style={{ width: '100%' }}>
                                                                            <tr className='titulo-1'>
                                                                                <td className='text-center bold td-data group-title' width={'1%'}>#</td>
                                                                                <td className='text-left bold td-data' width={'5%'}>Codigo Lançamento</td>
                                                                                <td className='text-left bold td-data' width={'10%'}>Data Liquidação</td>
                                                                                <td className='text-left bold td-data' width={'30%'}>Categoria</td>
                                                                                <td className='bold td-data' width={'20%'}>Desc. Lançamento</td>
                                                                                <td className='bold td-data' width={'25%'}>Cliente</td>
                                                                                <td className='bold td-data text-center' width={'10%'}>Valor</td>
                                                                                {/* <td className='bold td-data text-center' width={'10%'}>Receita</td>
                                                                            <td className='bold td-data text-right' width={'10%'}>Despesa</td> */}
                                                                            </tr>

                                                                            {
                                                                                banco_contas?.lancamentos_liquidados && banco_contas.lancamentos_liquidados.length > 0 ? (
                                                                                    banco_contas.lancamentos_liquidados.map((lancamento_liquidados, idxLiquidados) => (
                                                                                        <>
                                                                                            {
                                                                                                lancamento_liquidados?.lancamentos?.map((lancamentos, idxLancamentos) => (
                                                                                                    <tr className={'tr-data bt-1'} key={idxLancamentos}>
                                                                                                        <td className='text-center td-data'>{idxLancamentos + 1}</td>
                                                                                                        <td className='text-left td-data'>{lancamentos.lancamento_id}</td>
                                                                                                        <td className='text-left td-data'>{lancamento_liquidados.data_liquidacao}</td>
                                                                                                        <td className='td-data'>{lancamentos.categoria}</td>
                                                                                                        <td className='td-data'>{lancamentos.descricao_lancamento}</td>
                                                                                                        <td className='td-data text-left'>{lancamentos.cliente}</td>
                                                                                                        <td className='td-data text-right'>{formatarMoeda(lancamentos.valor)}</td>
                                                                                                        {/* <td className='td-data text-right'>{formatarMoeda(lancamentos.receita)}</td>
                                                                                                    <td className='td-data text-right'>{formatarMoeda(lancamentos.despesa)}</td> */}
                                                                                                    </tr>
                                                                                                ))
                                                                                            }

                                                                                            <tr>
                                                                                                <td colSpan={8} className='td-data text-right' style={{ borderTop: '1px #000 dashed' }}>
                                                                                                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                                                                        <b> {formatarMoeda(lancamento_liquidados.saldo_dia_total)}</b>
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>

                                                                                        </>
                                                                                    ))


                                                                                ) : (
                                                                                    <tr>
                                                                                        <td colSpan='8' className='text-center td-data'>Nenhum Lançamento Encontrado</td>
                                                                                    </tr>
                                                                                )
                                                                            }


                                                                            {

                                                                                <tr>
                                                                                    <td colSpan={8} className='td-data text-right' style={{ borderTop: '1px #000 dashed' }}>
                                                                                        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                                                            <b> Total Receitas: {formatarMoeda(totalReceita)}</b>
                                                                                        </div>

                                                                                        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                                                            <b> Total Despesas: {formatarMoeda(totalDespesa * (- 1))}</b>
                                                                                        </div>

                                                                                        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                                                            <b> Aplicação: {formatarMoeda(totalAplicacao)}</b>
                                                                                        </div>

                                                                                        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                                                            <b> Resgate: {formatarMoeda(totalResgate)}</b>
                                                                                        </div>

                                                                                        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                                                                            <b> Saldo Atual: {formatarMoeda(saldoAtual)}</b>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>

                                                                            }

                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })}


                                            </>

                                        )


                                    })
                                }

                            </table>
                        </>
                    )}
                />

                {/* <table className='table-agrupamento mb-2'>
                    <tr>
                        {x.colunas && arrayConfigCol[idx][idxSaldo]?.visivel ? (
                            <td className='td-data text-right'><b>Total Geral: {formatarMoeda(valorTotalGeral)}</b>
                            </td>
                        ) : null}

                    </tr>
                </table> */}
                <div className='delete-ends' />

            </>)
        }) : <MensagemPadraoRelatorio />
    )
}

export { relatorioMovimentacoesBancariasEspecifico, relatorioMovimentacoesBancariasVirtuoso }