import ls from 'local-storage'
import axios from 'axios';
import { get, post } from '../util/AcessoApi';
import moment from "moment"
import { MODULO, MODULOS_SISTEMA } from './Enums';


const URL_BACKEND = (process.env.REACT_APP_ENV.toString() === 'production' ? process.env.REACT_APP_URL_PRODUCTION : process.env.REACT_APP_URL_DEVELOPMENT)

const getAutenticado = _ => {
    const user = ls.get('usuario')
    return user !== null
}

function setSistemaStatus(setFunction) {
    if (!isFunction(setFunction))
        return;
    try {
        Promise.resolve(obterStatusSistema()).then(function (ret) {
            if (ret == "on")
                setFunction(false)
            else
                setFunction(true)

        }).catch(e => {
            //
            setFunction(true)
        })
    } catch (error) {
        setFunction(true)
        throw error
    }
}

const setSistemaVersao = async (setFunction) => {
    if (!isFunction(setFunction))
        return;
    try {
        Promise.resolve(obterVersaoBackend()).then(function (ret) {
            setFunction(ret)

        }).catch(e => {
            //
            setFunction('off')
        })
    } catch (error) {
        setFunction('off')
        throw error
    }
}
function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}
const obterStatusSistema = async () => {
    let ret = "off";
    const errorFunction = function (err) {
        ret = "off"
    }
    await get('/sistema/status', null, null, errorFunction).then(function (res) {
        ret = res !== "on" ? "off" : res;
    })

    return ret;
}
const obterVersaoBackend = async () => {
    let ret2 = "off";
    const errorFunction = function (err) {
        ret2 = "off"
    }
    await get('/sistema/versaobackend', null, null, errorFunction).then(function (res) {
        ret2 = res;
    })

    return ret2;
}
const getUsuario = _ => ls.get('usuario')

const getConfig = () => {
    const user = getUsuario()

    return {
        headers: {
            'Authorization': (user && user.token) || '',
            'Access-Control-Allow-Origin': '*'
        }
    }
}

const getApi = (setData, url, callback, modulo, noAuth) => {
    const user = getUsuario()

    const config = {
        headers: { 'Authorization': (user && user.token) || '' },
        params: {
            modulo_id: modulo
        }
    }

    if (noAuth)
        axios.get(URL_BACKEND + url)
            .then(res => {
                setData(res.data)
                callback && callback()
            })
            .catch(res => console.log('ERRO,', res))
    else
        axios.get(URL_BACKEND + url, config)
            .then(res => {
                setData(res.data)
                callback && callback()
            })
            .catch(res => console.log('ERRO ', res))

}

const getApiSetLocalStorage = (chaveLocalStorage, url, callback) => {
    return axios.get(URL_BACKEND + url, getConfig())
        .then(res => {
            ls.set(chaveLocalStorage, res.data)
            callback && callback()
            return res.data
        })
}

const logout = async (auditar) => {
    await new Promise(async (resolve, reject) => {
        if (auditar !== false) {
            let user = getUsuario()
            await Promise.resolve(post('/controleAcesso/usuario/logout', user, false, null, true)).then(function (val) {
            })
        }
        await ls.remove('usuario')
        await ls.remove('permissaoDashboard')
        await ls.remove('ultimo_hora_login')
        resolve();
    })
}

const reload = () => {
    window.location.reload();
}

const MENU = [
    {
        id: 'dashboard',
        nome: 'Dashboard',
        icone: 'icon-Dashboard',
        caminho: '/dashboard',
        modulo: 66
    },
    {
        id: 'administrativo',
        nome: 'Administrativo',
        icone: 'icon-Add-Bag',
        submenu: [
            {
                nome: 'Empresa',
                icone: 'icon-Building',
                caminho: '/administracao/empresa/consulta',
                modulo: 5
            },
            {
                nome: 'Sócio',
                submenu: [{
                    nome: 'Sócio',
                    icone: 'icon-Doctor',
                    caminho: '/administracao/socio/consulta',
                    modulo: 6
                }, {
                    nome: 'Ocorrência',
                    icone: 'icon-Barricade-2',
                    caminho: '/administracao/socio-ocorrencia/consulta',
                    modulo: 17
                },
                {
                    nome: 'Tipo Ocorrência',
                    icone: 'icon-Refinery',
                    caminho: '/administracao/tipo-ocorrencia/consulta',
                    modulo: 124
                }
                ]
            },
            {
                nome: 'Cirurgião',
                icone: 'icon-Mens',
                caminho: '/administracao/cirurgiao/consulta',
                modulo: 10
            },
            {
                nome: 'Estabelecimento Saúde',
                icone: 'icon-Hospital-2',
                caminho: '/administracao/estabelecimentoSaude/consulta',
                modulo: 9
            },
            {
                nome: 'Operadora de Saúde',
                icone: 'icon-Geo3-',
                caminho: '/administracao/operadoraSaude/consulta',
                modulo: 8
            },
            {
                nome: 'CBOS',
                icone: 'icon-Medical-Sign',
                caminho: '/parametros/cbos/consulta',
                modulo: 7
            },
            {
                nome: 'Departamento',
                icone: 'icon-Building',
                caminho: '/administracao/departamento/consulta',
                modulo: 29
            },
            {
                nome: 'Feriados',
                icone: 'icon-Holiday',
                caminho: '/administracao/feriados/consulta',
                modulo: 67
            },
            {
                nome: 'Notificação',
                icone: 'icon-New-Mail',
                caminho: '/administracao/notificacao/consulta',
                modulo: 71
            },
            {
                nome: 'Direito Folga',
                icone: 'icon-Air-Balloon',
                caminho: '/administracao/direitofolga/consulta',
                modulo: 72
            },
        ]
    },
    {
        id: 'agenda',
        nome: 'Agenda/Escala',
        icone: 'icon-Calendar-3',
        submenu: [
            {
                nome: 'Escalador',
                icone: 'icon-Business-Mens',
                caminho: '/administracao/escalador/consulta',
                modulo: 11
            },
            {
                nome: 'Agenda Escalador',
                icone: 'icon-Calendar-3',
                caminho: '/administracao/agendaEscalador/consulta',
                modulo: 15
            },
            {
                nome: 'Agenda/Central de Reserva',
                icone: 'icon-Calendar-3',
                caminho: '/administracao/agendareserva/consulta',
                modulo: 38
            },
            {
                nome: 'Escala Plantão',
                icone: 'icon-Clock',
                caminho: '/administracao/plantao-alcancaveis/consulta',
                modulo: 49
            },
            {
                nome: 'Escala Estabelecimento',
                icone: 'icon-Building',
                caminho: '/administracao/plantao-estabelecimento/consulta',
                modulo: 53,
            },
            {
                nome: 'Registro de Atividades',
                icone: 'icon-Address-Book2',
                caminho: '/administracao/registroatividades/consulta',
                modulo: 39
            },
            {
                nome: 'Rotinas',
                icone: 'icon-Dec',
                caminho: '/administracao/rotinas/consulta',
                modulo: 54
            },
            {
                nome: 'Grupo Escala',
                icone: 'icon-Mens',
                caminho: '/administracao/grupo-escala/consulta',
                modulo: 64
            }
        ]
    },
    {
        id: 'ferias',
        nome: 'Férias',
        icone: 'icon-Yacht',
        submenu: [
            {
                nome: 'Ano (Gozo)',
                icone: 'icon-Calendar-4',
                caminho: '/administracao/ferias/periodo-gozo/consulta',
                modulo: 56
            },
            {
                nome: 'Dias para Gozo',
                icone: 'icon-Address-Book2',
                caminho: '/administracao/ferias/lancamento-saldo/consulta',
                modulo: 58
            }
            ,
            {
                nome: 'Marcação',
                icone: ' icon-Yacht',
                caminho: '/administracao/ferias/marcacao/consulta',
                modulo: 59
            }
            ,
            {
                nome: 'Mercado',
                icone: 'icon-Add-Cart',
                caminho: '/administracao/ferias/mercado/consulta',
                modulo: 59
            }
        ]
    },
    {
        id: 'faturamento',
        nome: 'Faturamento',
        icone: 'icon-Coins',
        submenu: [
            {
                nome: 'Tabela Procedimento',
                icone: ' icon-File-ClipboardFileText',
                caminho: '/faturamento/tabelaProcedimento/consulta',
                modulo: 12
            }, {
                nome: 'Procedimento',
                icone: ' icon-File-ClipboardFileText',
                caminho: '/faturamento/procedimento/consulta',
                modulo: 14
            },
            {
                nome: 'Acomodação',
                icone: ' icon-bed',
                caminho: '/faturamento/acomodacao/consulta',
                modulo: 30
            },
            {
                nome: 'Beneficiário',
                icone: ' icon-Mens',
                caminho: '/faturamento/beneficiario/consulta',
                modulo: 32
            }, {
                nome: 'CID-10',
                icone: 'icon-First-Aid',
                caminho: '/faturamento/cid10/consulta',
                modulo: 31
            },
            {
                nome: 'Contrato',
                icone: 'icon-Professor',
                caminho: '/faturamento/contrato/consulta',
                modulo: 34
            },
            {
                nome: 'Lote',
                icone: 'icon-Big-Data',
                caminho: '/faturamento/lote/consulta',
                modulo: 35
            },
            {
                nome: 'Guia',
                icone: 'icon-File-TextImage',
                caminho: '/faturamento/guia/consulta',
                modulo: 76
            },
            {
                nome: 'Parâmetro Operadora Saúde',
                icone: 'icon-Equalizer',
                caminho: '/faturamento/parametroOperadoraSaude/consulta',
                modulo: 74
            },
            {
                nome: 'Particular',
                submenu: [{
                    nome: 'Contrato',
                    icone: 'icon-File-TextImage',
                    caminho: '/faturamento/contratoparticular/consulta',
                    modulo: 107
                },
                {
                    nome: 'Guia',
                    icone: 'icon-File-TextImage',
                    caminho: '/faturamento/guia-particular/consulta',
                    modulo: 107
                },
                {
                    nome: 'Tabela Anestesia Odontológica',
                    icone: 'icon-Barricade-2',
                    caminho: '/faturamento/anestesiaodontologia/consulta',
                    modulo: 80
                },
                {
                    nome: 'Porte Anestésico',
                    icone: 'icon-File-ClipboardTextImage',
                    caminho: '/faturamento/porteanestesicoparticular/consulta',
                    modulo: 80
                }
                ]
            },
        ]
    },
    {
        id: 'movimento-financeiro',
        nome: 'Financeiro',
        icone: 'icon-Money-2',

        submenu: [
            {
                nome: 'Banco',
                icone: 'icon-Bank',
                caminho: '/financeiro/banco/consulta',
                modulo: 125
            },
            {
                nome: 'Conta Bancária',
                icone: 'icon-Bank',
                caminho: '/financeiro/contabancaria/consulta',
                modulo: 116
            },
            {
                nome: 'Categoria',
                icone: 'icon-Posterous',
                caminho: '/financeiro/categoria/consulta',
                modulo: 135
            },
            {
                nome: 'Cliente',
                icone: ' icon-Business-ManWoman',
                caminho: '/financeiro/cliente/consulta',
                modulo: 115
            },
            {
                nome: 'Lançamento Financeiro',
                icone: ' icon-Receipt-3',
                caminho: '/financeiro/lancamentofinanceiro/consulta',
                modulo: 134
            },
            {
                nome: 'Movimento Diário',
                submenu: [
                    {
                        nome: 'Movimento Financeiro',
                        icone: ' icon-Receipt',
                        caminho: '/financeiro/movimento/consulta',
                        modulo: 114
                    },
                    {
                        nome: 'Lançamento Financeiro',
                        icone: 'icon-Receipt-3',
                        caminho: '/financeiro/lancamento/consulta',
                        modulo: 116
                    },
                ]
            },
            {
                nome: 'Tributo',
                submenu: [
                    {
                        nome: 'Tributo',
                        icone: ' icon-Receipt',
                        caminho: '/financeiro/tributo/consulta',
                        modulo: 126
                    },
                    {
                        nome: 'Tributo Alíquota',
                        icone: ' icon-Basket-Coins',
                        caminho: '/financeiro/tributoaliquota/consulta',
                        modulo: 131
                    },
                ]
            },
            {
                nome: 'Nota Fiscal',
                icone: ' icon-Receipt-3',
                caminho: '/financeiro/notafiscal/consulta',
                modulo: 136
            },
            {
                nome: 'Cheque',
                icone: 'icon-File-HorizontalText',
                caminho: '/financeiro/cheque/consulta',
                modulo: 133
            }
        ]
    },
    {
        id: 'auditoria',
        nome: 'Auditoria',
        icone: 'icon-Bar-Chart3',

        submenu: [
            {
                nome: 'Guia Pagamento',
                icone: 'icon-Bank',
                caminho: '/auditoria/guia-pagamento/consulta',
                modulo: 148
            },
        ]
    },
    {
        id: 'controle-producao',
        nome: 'Controle de Produção',
        icone: 'icon-Bar-Chart',
        submenu: [{
            nome: 'Referência Produção',
            icone: ' icon-Calendar-4',
            caminho: '/controleproducao/referencia/consulta',
            modulo: 140
        },
        {
            nome: 'Guia Mapa de Horas',
            icone: ' icon-Calendar-4',
            caminho: '/controleproducao/guiamapahoras/consulta',
            modulo: 145
        }]
    },
    {
        id: 'relatorios',
        nome: 'Relatórios',
        icone: 'icon-File-ClipboardFileText',
        submenu: [
            {
                nome: 'Administrativo',
                submenu: [
                    {
                        nome: 'Sócio',
                        icone: ' icon-Doctor',
                        caminho: '/relatorios/socio/relatorio',
                        modulo: 27,
                        modulos_relatorio: [MODULOS_SISTEMA.RELATORIO_SOCIO_GERAL, MODULOS_SISTEMA.RELATORIO_SOCIO_CONJUGE, MODULOS_SISTEMA.RELATORIO_SOCIO_DEPENDENTE, MODULOS_SISTEMA.RELATORIO_SOCIO_ENDERECO, MODULOS_SISTEMA.RELATORIO_SOCIO_EMPRESA, MODULOS_SISTEMA.RELATORIO_SOCIO_GRUPO, MODULOS_SISTEMA.RELATORIO_SOCIO_HIERARQUIZACAO]
                    },
                    {
                        nome: 'Cirurgião',
                        icone: 'icon-Mens',
                        caminho: '/relatorios/cirurgiao/relatorio',
                        modulo: 41
                    },
                    {
                        nome: 'Ocorrência',
                        icone: 'icon-Barricade-2',
                        caminho: '/relatorios/ocorrencia/relatorio',
                        modulo: 63
                    },
                ]
            },
            {
                nome: 'Agenda/Escala',
                submenu: [
                    {
                        nome: 'Agendamento',
                        icone: 'icon-Calendar-3',
                        caminho: '/relatorios/agendamento/relatorio',
                        modulo: 65,
                        modulos_relatorio: [MODULOS_SISTEMA.RELATORIO_AGENDA_CENTRAL_RESERVA, MODULOS_SISTEMA.RELATORIO_ESTABELECIMENTO_SAUDE_DESCOBERTO]
                    },
                    {
                        nome: 'Escala',
                        icone: 'icon-Clock',
                        caminho: '/relatorios/plantao/relatorio',
                        modulo: 51
                    },
                ]
            },
            {
                nome: 'Auditoria',
                submenu: [
                    {
                        nome: ' Apresentado x Recebido',
                        icone: ' icon-Receipt',
                        caminho: '/relatorios/auditoria/relatorio',
                        modulo: 149,
                        modulos_relatorio: [MODULOS_SISTEMA.RELATORIO_APRESENTADO_RECEBIDO_ESTABELECIMENTO_SAUDE, MODULOS_SISTEMA.RELATORIO_APRESENTADO_RECEBIDO_OPERADORA_SAUDE, MODULOS_SISTEMA.RELATORIO_APRES_RECEB_ANALITICO_ESTABELECIMENTO_SAUDE, MODULOS_SISTEMA.RELATORIO_APRES_RECEB_PACIENTE_GUIA, MODULOS_SISTEMA.RELATORIO_ANUAL_GUIAS_NAO_BAIXADAS]
                    },
                ]
            },
            {
                nome: 'Controle de Produção',
                submenu: [
                    {
                        nome: 'Guias Mapas de Horas',
                        icone: 'icon-Calendar-4',
                        caminho: '/relatorios/guiasmapahoras/relatorio',
                        modulo: 143
                    },
                ]
            },
            {
                nome: 'Faturamento',
                submenu: [
                    {
                        nome: 'Procedimento',
                        icone: 'icon-File-ClipboardFileText',
                        caminho: '/relatorios/procedimento/relatorio',
                        modulo: 37,
                        modulos_relatorio: [MODULOS_SISTEMA.RELATORIO_DURACAO_PROCEDIMENTO, MODULOS_SISTEMA.RELATORIO_DURACAO_PROCEDIMENTO_CIRURGIA]
                    },
                    {
                        nome: 'Lote',
                        icone: 'icon-Big-Data',
                        caminho: '/relatorios/lote/relatorio',
                        modulo: 139
                    },
                    {
                        nome: 'Guia',
                        icone: 'icon-File-TextImage',
                        caminho: '/relatorios/guia/relatorio',
                        modulo: 77,
                        modulos_relatorio: [MODULOS_SISTEMA.RELATORIO_AGRUPADO_ESTABELECIMENTO_SAUDE, MODULOS_SISTEMA.RELATORIO_AGRUPADO_OPERADORA_SAUDE, MODULOS_SISTEMA.RELATORIO_AGRUPADO_SOCIO_EQUIPE, MODULOS_SISTEMA.RELATORIO_AGRUPADO_SOCIO_COOPANEST_GO, MODULOS_SISTEMA.RELATORIO_GUIA_GERAL, MODULOS_SISTEMA.RELATORIO_GUIA_GERAL_CORRECAO, MODULOS_SISTEMA.RELATORIO_ANALITICO_ESTABELECIMENTO_SAUDE, MODULOS_SISTEMA.RELATORIO_GUIA_NFSE]
                    },
                    {
                        nome: 'Faturamento',
                        icone: 'icon-Coins',
                        caminho: '/relatorios/fatura/relatorio',
                        modulo: 78,
                        modulos_relatorio: [MODULOS_SISTEMA.RELATORIO_RESUMO_FATURAMENTO_OPERADORA_SAUDE, MODULOS_SISTEMA.RELATORIO_RESUMO_FATURAMENTO_ESTABELECIMENTO_SAUDE, MODULOS_SISTEMA.RELATORIO_SINTETICO_PRODUCAO_DIGITACAO, MODULOS_SISTEMA.RELATORIO_ANALITICO_PRODUCAO_DIGITACAO, MODULOS_SISTEMA.RELATORIO_ANALITICO_ESTABELECIMENTO_SAUDE, MODULOS_SISTEMA.RELATORIO_DE_GUIAS_CONFERIDA_SINTETICO, MODULOS_SISTEMA.RELATORIO_DE_GUIAS_CONFERIDA_ANALITICO]
                    },
                    {
                        nome: 'Guias Enviadas',
                        icone: 'icon-Mail-Send',
                        caminho: '/relatorios/guiasenviadas/relatorio',
                        modulo: 113
                    },

                ]
            },

            {
                nome: 'Férias',
                submenu: [
                    {
                        nome: 'Férias',
                        icone: 'icon-Yacht',
                        caminho: '/relatorios/ferias/relatorio',
                        modulo: 61,
                        modulos_relatorio: [MODULOS_SISTEMA.RELATORIO_PROFISSIONAL_UTILIZADO_RECEBIDO, MODULOS_SISTEMA.RELATORIO_PROFISSIONAL_UTILIZADO_RECEBIDO_RESUMO]
                    },
                    {
                        nome: 'Mercado',
                        icone: 'icon-Add-Cart',
                        caminho: '/relatorios/mercado/relatorio',
                        modulo: 61
                    },

                ]
            },

            {
                nome: 'Financeiro',
                submenu: [
                    {
                        nome: 'Movimento Financeiro',
                        icone: 'icon-Receipt',
                        caminho: '/relatorios/movimento/relatorio',
                        modulo: 117,
                        modulos_relatorio: [MODULOS_SISTEMA.RELATORIO_MOVIMENTO_SINTETICO, MODULOS_SISTEMA.RELATORIO_MOVIMENTO_ANALITICO, MODULOS_SISTEMA.RELATORIO_MOVIMENTO_RECEITA]
                    },
                    {
                        nome: 'Lançamento Movimento Financeiro',
                        icone: 'icon-Receipt-3',
                        caminho: '/relatorios/lancamentomovimento/relatorio',
                        modulo: 118
                    },
                    {
                        nome: 'Cliente',
                        icone: 'icon-Business-ManWoman',
                        caminho: '/relatorios/cliente/relatorio',
                        modulo: 137
                    },
                    {
                        nome: 'Contas a Pagar/Receber',
                        icone: 'icon-Receipt-3',
                        caminho: '/relatorios/contasreceber/relatorio',
                        modulo: 138,
                        modulos_relatorio: [MODULOS_SISTEMA.RELATORIO_DE_CONTAS_A_PAGAR_RECEBER]
                    },
                    {
                        nome: 'Nota Fiscal',
                        icone: 'icon-Receipt-3',
                        caminho: '/relatorios/notafiscal/relatorio',
                        modulo: 157,
                        modulos_relatorio: [MODULOS_SISTEMA.RELATORIO_DE_NOTAS_FISCAIS, MODULOS_SISTEMA.RELATORIO_DMS]
                    },
                    {
                        nome: 'Relatório de Extrato Bancário',
                        icone: 'icon-Bank',
                        caminho: '/relatorios/extratobancario/relatorio',
                        modulo: 163,
                        modulos_relatorio: [MODULOS_SISTEMA.RELATORIO_EXTRATO_BANCARIO_DIARIO, MODULOS_SISTEMA.RELATORIO_MOVIMENTACOES_BANCARIAS, MODULOS_SISTEMA.RELATORIO_FLUXO_CAIXA]
                    },
                ]
            },
        ]
    },
    {
        id: 'analise',
        nome: 'Análise de Dados',
        icone: 'icon-File-Graph',
        submenu: [
            {
                nome: 'Produção Faturamento',
                icone: 'icon-File-Graph',
                caminho: '/analisedados/producaofaturamento/grafico',
                modulo: 146
            },
        ]
    },
    {
        id: 'controle-acesso',
        nome: 'Controle de Acesso',
        icone: 'icon-Add-User',
        submenu: [
            {
                nome: 'Usuario',
                icone: 'icon-Add-User',
                caminho: '/controleAcesso/usuario/consulta',
                modulo: 1
            },
            {
                nome: 'Grupo',
                icone: ' icon-Business-ManWoman',
                caminho: '/controleAcesso/grupo/consulta',
                modulo: 2
            },
            {
                nome: 'Auditoria',
                icone: ' icon-File-Search',
                caminho: '/controleAcesso/auditoria/consulta',
                modulo: 42
            },
            {
                nome: 'Auditoria App',
                icone: ' icon-File-Search',
                caminho: '/controleAcesso/auditoria-app/consulta',
                modulo: 48
            },
            {
                nome: 'Usuário App',
                icone: ' icon-Smartphone-2',
                caminho: '/controleAcesso/usuarioApp/consulta',
                modulo: 44
            },
            {
                nome: 'Lixeira',
                icone: ' icon-New-Trash',
                caminho: '/controleAcesso/lixeira/consulta',
                modulo: 73
            },
            {
                nome: 'Rascunho',
                icone: 'icon-Box-withFolders',
                caminho: '/controleAcesso/rascunho/consulta',
                modulo: 144
            }
        ]
    },
    {
        nome: 'Sair',
        icone: 'icon-Power',
        caminho: '/logout',
    },
]
const temPermissao = (moduloId, permissoes) => {
    const permissao = permissoes.find(p => p.modulo_id === moduloId);
    return permissao && permissao.permissoes.some(p => p !== null);
}

const obterMenuComPermissoes = async () => {
    try {
        let retorno = null
        await get('/controleAcesso/permissao/obterTodas', { usuario_id: getUsuario().id }).then(function (array) {
            let copia = MENU

            let s = copia.map(x => {
                x.permitido = x.submenu === undefined || x.submenu.filter(y => y.permitido === true).length > 0
                if (x.submenu != undefined) {

                    x.submenu.map((z, idx) => {
                        if (z.submenu) {
                            const lvSubmenu = z.submenu.filter(lv3 => {
                                const arrayFiltrado = array.filter(ar => ar.modulo_id.toString() === lv3.modulo.toString())[0];
                                return arrayFiltrado.permissoes != null && arrayFiltrado.permissoes[0] != null
                            })
                            const lvSubmenuRelatorio = z.submenu.map(lv3 => {
                                if (lv3.modulos_relatorio) {
                                    const arrayFiltrado = array.filter(ar => lv3.modulos_relatorio.includes(ar.modulo_id))
                                    return arrayFiltrado.filter(x => x.permissoes != null && x.permissoes[0] != null)

                                }
                                return null

                            }).filter(x => x)
                            const permissoes = lvSubmenuRelatorio.flat();
                            const resultado = z?.submenu?.filter(item => {
                                if (item.modulo && permissoes.some(p => p.modulo_id === item.modulo && p.permissoes.some(permissao => permissao !== null))) {
                                    return true;
                                }
                                if (item.modulos_relatorio && item.modulos_relatorio.some(mod => permissoes.some(p => p.modulo_id === mod && p.permissoes.some(permissao => permissao !== null)))) {
                                    return true;
                                }

                                return false;
                            });

                            if (!(lvSubmenu.length > 0)) {
                                z.permitido = false
                            }
                            else {
                                if (resultado.length > 0) {
                                    resultado.map(item => {
                                        let { modulo } = item
                                        if (!lvSubmenu.some(x => (x.modulo === modulo)))
                                            lvSubmenu.push(item)
                                    })
                                }
                                z.submenu = lvSubmenu
                                z.permitido = true
                            }

                            return;
                        } else {
                            let submenuPermitido = true
                            if (z.modulo != undefined) {
                                let permissoes = array.filter(ar => ar.modulo_id.toString() == z.modulo.toString())[0].permissoes.filter(z => z != null)
                                submenuPermitido = permissoes != undefined ? permissoes.length > 0 : true
                                z.array = permissoes
                            }
                            z.permitido = submenuPermitido
                            return z
                        }
                    })
                }
                return x
            })

            try {
                if (s && s[0] && s[0].modulo == 66 && array.filter(ar => ar.modulo_id === 66).find(ar => ar).permissoes.filter(x => x !== null).length < 1) {
                    s.splice(0, 1);
                }
            } catch (error) {

            }


            retorno = s
        })



        return retorno == null ? MENU : retorno;
    } catch (error) {
        return MENU
    }

}

function minutosEmInatividade() {
    if (!ls.get('usuario'))
        return 9000;
    let segundosUltimoLogin = 0;
    try {
        segundosUltimoLogin = moment().diff(ls.get('ultimo_hora_login'), 'minutes');
    }
    catch (e) {
        console.log(e, 'erro ao calcular tempo ultimo login login');
    }
    return segundosUltimoLogin;
}

function verificarPermissao(modulo, acao, funcao) {
    let user = ls.get('usuario');
    const objPermissao = {
        usuario_id: (user && user.id) || 0,
        modulo_id: modulo,
        acao_id: acao
    }

    get('/controleAcesso/permissao', objPermissao).then(val => {
        if (val.length)
            funcao(true);
        else
            funcao(false);

    }).catch(e => {
        funcao(false);
    })
}

export { URL_BACKEND, getAutenticado, logout, reload, minutosEmInatividade, MENU, getApi, getUsuario, getConfig, getApiSetLocalStorage, obterMenuComPermissoes, setSistemaStatus, setSistemaVersao, verificarPermissao }